/* searchstyles.css */
.search-buttons {
  display: flex;
}

.search-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 25px;
  background-color: black;
  width: 200px;
  margin-left: 50px;
  margin-right: 20px;
  padding: 5px 25px;
  font-family:Copperplate, Papyrus, fantasy;
}

.search-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.search-buttons input {
  color: white;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 25px;
  background-color: black;
  width: 200px;
  margin-left: 50px;
  margin-right: 20px;
  padding: 8px 25px;
  font-family:Copperplate, Papyrus, fantasy;
}
