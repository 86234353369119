/* YearPagination.css */

.year-pagination {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-left: 50px;
}

.year-pagination button {
  color:white;
  padding: 5px 20px;
  background-color: black;
  cursor: pointer;
  border-radius:5px;
}

.year-pagination button.active {
  background-color: black;
  font-weight: bold;
  border: 1px solid #ccc;
}

