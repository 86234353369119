/* Footer.css */

.footer {
  background-color: black;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  //padding-left: 50px;
  //padding-right: 150px;
}

/* Media Query for Responsive Footer */
@media (max-width: 600px) {
  .footer-links {
    flex-wrap: wrap;
  }

  .footer-links a {
    margin: 5px;
  }
}

