/* DecadePagination.css */

.decade-pagination {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-left:50px;
}

.decade-pagination button {
  color: white;
  padding: 5px 20px;
  background-color: black;
  cursor: pointer;
  border-radius:10px;
}

.decade-pagination button.active {
  color: white;
  background-color: black;
  font-weight: bold;
  border: 1px solid #ccc;
}
