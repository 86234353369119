/* prev-nextstyles.css */
.prev-next-buttons {
  display: flex;
}

.next-buttons {
  display: flex;
  float: right;
}

.next-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  width: 150px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  width: 150px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.prev-next-buttons button[disabled] {
  color: gray;
  border: 1px solid gray;
  background-color: #gray;
}


.prev-next-actors-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  height: 160px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-actors-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.prev-next-actors-buttons button[disabled] {
  color: gray;
  border: 1px solid gray;
  background-color: #gray;
}
