/* tabstyles.css */
.tab-buttons {
  display: flex;
}

.tab-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 25px;
  background-color: black;
  width: 200;
  margin: 30px;
  padding: 10px 25px;
  font-family:Copperplate, Papyrus, fantasy;
}

.tab-buttons button.active {
  border: 1px solid white;
  border-radius: 25px;
  background-color: #black;
}

.tab-content {
  margin-top: 10px;
  padding: 10px;
  //border: 1px solid #ccc;
}
